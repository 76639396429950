import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useNavigate } from 'react-router-dom';
import ProductList from './ProductList';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const PRODUCT_FOLDER = 'loja-produtos/produtos/';

const ProductManager = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchProducts = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: PRODUCT_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const productPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const productData = await Promise.all(productPromises);
        setProducts(productData.filter(prod => prod !== null));
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleDeleteProduct = async (id) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const product = products.find(p => p.id === id);
      if (!product) {
        console.error('Produto não encontrado:', id);
        alert('Produto não encontrado.');
        return;
      }

      const sanitizedCategory = sanitizeFileName(product.categoria);
      const sanitizedName = sanitizeFileName(product.nome);
      const productFolder = `${PRODUCT_FOLDER}${sanitizedCategory}/${id}-${sanitizedName}/`;

      // List all objects in the product folder
      const listParams = {
        Bucket: S3_BUCKET,
        Prefix: productFolder,
      };
      const listedObjects = await s3.listObjectsV2(listParams).promise();

      // Delete all objects in the product folder
      const deleteParams = {
        Bucket: S3_BUCKET,
        Delete: {
          Objects: listedObjects.Contents.map(item => ({ Key: item.Key }))
        }
      };

      await s3.deleteObjects(deleteParams).promise();

      setProducts(products.filter(p => p.id !== id));
      alert('Produto excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir produto:', error);
      alert('Erro ao excluir produto. Verifique as configurações e tente novamente.');
    }
  };

  const handleEditTexts = (product) => {
    navigate(`/edit-product-texts/${product.id}`, { state: { product } });
  };

  const handleEditImages = (product) => {
    navigate(`/edit-product-images/${product.id}`, { state: { product } });
  };

  return (
    <div>
      <h1>Gerenciamento de Produtos</h1>
      <ProductList
        products={products}
        onDelete={handleDeleteProduct}
        onEditTexts={handleEditTexts}
        onEditImages={handleEditImages}
      />
      <button onClick={() => navigate('/new-product')}>Adicionar Novo Produto</button>
    </div>
  );
};

const sanitizeFileName = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
};

export default ProductManager;
