import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import CategoryForm from './CategoryForm';
import CategoryList from './CategoryList';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const CATEGORY_FOLDER = 'loja-produtos/categorias/';

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCategories = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: CATEGORY_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const categoryPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const categoryData = await Promise.all(categoryPromises);
        setCategories(categoryData.filter(cat => cat !== null));
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleSaveCategory = (category) => {
    setCategories([...categories, category]);
    setSelectedCategory(null);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleDeleteCategory = async (id) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const category = categories.find(c => c.id === id);
      if (!category) {
        console.error('Categoria não encontrada:', id);
        alert('Categoria não encontrada.');
        return;
      }

      const jsonFileName = `${CATEGORY_FOLDER}${id}-${sanitizeFileName(category.nome)}.json`;

      const deleteParamsJson = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
      };

      console.log('Tentando excluir o arquivo JSON:', jsonFileName);
      await s3.deleteObject(deleteParamsJson).promise();
      console.log('Arquivo JSON excluído com sucesso:', jsonFileName);

      setCategories(categories.filter(c => c.id !== id));
      alert('Categoria excluída com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir categoria:', error);
      alert('Erro ao excluir categoria. Verifique as configurações e tente novamente.');
    }
  };

  const handleCancel = () => {
    setSelectedCategory(null);
  };

  return (
    <div>
      <h1>Gerenciamento de Categorias</h1>
      <CategoryForm
        selectedCategory={selectedCategory}
        onSave={handleSaveCategory}
        onCancel={handleCancel}
      />
      <CategoryList
        categories={categories}
        onEdit={handleEditCategory}
        onDelete={handleDeleteCategory}
      />
    </div>
  );
};

const sanitizeFileName = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9]/g, '-')
    .replace(/-+/g, '-')
    .replace(/^-|-$/g, '');
};

export default CategoryManager;
