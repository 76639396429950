import React from 'react';

const NumberInput = ({ label, value, onChange, required = false, step = "0.01" }) => (
  <div>
    <label>{label}</label>
    <input type="number" value={value} onChange={onChange} required={required} step={step} />
  </div>
);

export default NumberInput;
