import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const CATEGORY_FOLDER = 'loja-produtos/categorias/';

const CategoryForm = ({ selectedCategory, onSave, onCancel }) => {
  const [nome, setNome] = useState('');

  useEffect(() => {
    if (selectedCategory) {
      setNome(selectedCategory.nome);
    }
  }, [selectedCategory]);

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      // Get the latest category number
      let latestNumber = 0;
      const listParams = {
        Bucket: S3_BUCKET,
        Prefix: CATEGORY_FOLDER,
      };
      const listedObjects = await s3.listObjectsV2(listParams).promise();

      if (listedObjects.Contents && listedObjects.Contents.length > 0) {
        const latestFile = listedObjects.Contents.map(item => item.Key)
          .filter(key => key.startsWith(CATEGORY_FOLDER))
          .sort()
          .pop();
        const match = latestFile.match(/(\d+)-/);
        if (match) {
          latestNumber = parseInt(match[1], 10);
        }
      }

      const nextNumber = String(latestNumber + 1).padStart(3, '0');
      const sanitizedFileName = sanitizeFileName(nome);
      const jsonFileName = `${CATEGORY_FOLDER}${nextNumber}-${sanitizedFileName}.json`;

      const categoryData = {
        id: nextNumber,
        nome
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(categoryData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Categoria salva com sucesso!');
      onSave(categoryData);
    } catch (error) {
      console.error('Erro ao salvar categoria:', error);
      alert('Erro ao salvar categoria. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Nome:</label>
        <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
      </div>
      <button type="submit">Salvar Categoria</button>
      <button type="button" onClick={onCancel}>Cancelar</button>
    </form>
  );
};

export default CategoryForm;
