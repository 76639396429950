import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useParams, useLocation } from 'react-router-dom';
import FileInput from './FileInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const COUPON_FOLDER = 'loja-produtos/cupons/';

const EditCouponImages = () => {
  const { id } = useParams();
  const location = useLocation();
  const [banner, setBanner] = useState(null);
  const [existingBanner, setExistingBanner] = useState('');

  useEffect(() => {
    if (location.state && location.state.coupon) {
      const coupon = location.state.coupon;
      setExistingBanner(coupon.banner);
    }
  }, [location.state]);

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]);
  };

  const handleRemoveBanner = () => {
    setBanner(null);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(location.state.coupon.codigo);
      const bannerFolder = `${COUPON_FOLDER}${id}-${sanitizedFileName}/banner/`;
      const jsonFileName = `${COUPON_FOLDER}${id}-${sanitizedFileName}/${sanitizedFileName}.json`;

      // Upload banner image to S3 with public read access
      let bannerImageName = '';
      if (banner) {
        const bannerImageFileName = `${bannerFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: banner,
          ContentType: banner.type,
          ACL: 'public-read'
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = bannerImageFileName;
      } else if (existingBanner) {
        bannerImageName = existingBanner;
      }

      const couponData = {
        ...location.state.coupon,
        banner: bannerImageName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageName}` : '',
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(couponData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Imagem do cupom atualizada com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar imagem do cupom:', error);
      alert('Erro ao atualizar imagem do cupom. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FileInput label="Imagem do Banner:" onChange={handleFileChange} />
      {banner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(banner)}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
          <button type="button" onClick={handleRemoveBanner}>Remover</button>
        </div>
      )}
      {existingBanner && !banner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={existingBanner}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      <button type="submit">Salvar Imagem</button>
    </form>
  );
};

export default EditCouponImages;
