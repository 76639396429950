import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import TextInput from './TextInput';
import TextArea from './TextArea';
import NumberInput from './NumberInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const CLIENT_FOLDER = 'loja-produtos/clientes/';

const ClientManager = () => {
  const [clientes, setClientes] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [nome, setNome] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [referencia, setReferencia] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchClientes = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: CLIENT_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const clientePromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const clienteData = await Promise.all(clientePromises);
        setClientes(clienteData.filter(cliente => cliente !== null));
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };

    fetchClientes();
  }, []);

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(nome);
      const id = String(clientes.length + 1).padStart(3, '0');
      const jsonFileName = `${CLIENT_FOLDER}${id}-${sanitizedFileName}.json`;

      const clienteData = {
        id,
        nome,
        endereco,
        numero,
        referencia,
        email,
        whatsapp
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(clienteData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Cliente salvo com sucesso!');

      setClientes(prevClientes => [...prevClientes, clienteData]);

      setNome('');
      setEndereco('');
      setNumero('');
      setReferencia('');
      setEmail('');
      setWhatsapp('');
      setShowAddForm(false);
    } catch (error) {
      console.error('Erro ao salvar cliente:', error);
      alert('Erro ao salvar cliente. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <div>
      <h1>Gerenciamento de Clientes</h1>
      <button onClick={() => setShowAddForm(true)}>Adicionar Cliente</button>
      {showAddForm && (
        <form onSubmit={handleSubmit}>
          <TextInput label="Nome:" value={nome} onChange={(e) => setNome(e.target.value)} required />
          <TextArea label="Endereço:" value={endereco} onChange={(e) => setEndereco(e.target.value)} required />
          <NumberInput label="Número:" value={numero} onChange={(e) => setNumero(e.target.value)} required />
          <TextInput label="Referência:" value={referencia} onChange={(e) => setReferencia(e.target.value)} />
          <TextInput label="Email:" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <NumberInput label="WhatsApp:" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} required />
          <button type="submit">Salvar Cliente</button>
        </form>
      )}
      <ul>
        {clientes.map((cliente, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ flexGrow: 1 }}>{cliente.nome} - {cliente.email} - {cliente.whatsapp}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientManager;
