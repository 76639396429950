import React, { useState } from 'react';
import { geradorDescricao } from './openai/api-descricao';

const DescricaoAI = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const generateDescription = async () => {
    if (!title) {
      alert('Por favor, insira o título do produto.');
      return;
    }

    setLoading(true);
    try {
      const generatedDescription = await geradorDescricao(title);
      setDescription(generatedDescription);
    } catch (error) {
      console.error(error);
      alert('Erro ao gerar descrição. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.input}
        type="text"
        placeholder="Título do Produto"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <button style={styles.button} onClick={generateDescription}>
        {loading ? 'Gerando...' : 'Gerar Descrição'}
      </button>
      <textarea
        style={styles.textarea}
        placeholder="Descrição do Produto"
        value={description}
        readOnly
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
  },
  input: {
    height: '40px',
    width: '300px',
    marginBottom: '16px',
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  button: {
    marginBottom: '16px',
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  textarea: {
    width: '300px',
    height: '100px',
    padding: '8px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
};

export default DescricaoAI;
