import axios from 'axios';

export const geradorDescricao = async (productName) => {
  const temperature = 0.6;
  const maxTokens = 250;
  const topP = 0.8;
  const frequencyPenalty = 0.3;
  const presencePenalty = 0.3;

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "Você é um assistente que ajuda a gerar descrições de produtos."
          },
          {
            role: "user",
            content: `Gere uma descrição detalhada para o produto: ${productName}`
          }
        ],
        temperature: temperature,
        max_tokens: maxTokens,
        top_p: topP,
        frequency_penalty: frequencyPenalty,
        presence_penalty: presencePenalty
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error fetching product description:', error);
    throw error;
  }
};
