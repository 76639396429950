import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import ProductList from './components/ProductList';
import ProductDetails from './components/ProductDetails';
import ProductForm from './components/ProductForm';
import CategoryManager from './components/CategoryManager';
import ProductManager from './components/ProductManager';
import EditProductTexts from './components/EditProductTexts';
import EditProductImages from './components/EditProductImages';
import ProductView from './components/ProductView';
import AdditionalManager from './components/AdditionalManager';
import CouponManager from './components/CouponManager';
import EditCouponTexts from './components/EditCouponTexts';
import EditCouponImages from './components/EditCouponImages';
import ClientManager from './components/ClientManager';
import TaxManager from './components/TaxManager';
import DescricaoAI from './components/DescricaoAI';

const App = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<ProductList />} />
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="/new-product" element={<ProductForm />} />
      <Route path="/edit-product/:id" element={<ProductForm />} />
      <Route path="/edit-product-texts/:id" element={<EditProductTexts />} />
      <Route path="/edit-product-images/:id" element={<EditProductImages />} />
      <Route path="/view-product/:id" element={<ProductView />} />
      <Route path="/categories" element={<CategoryManager />} />
      <Route path="/manage-products" element={<ProductManager />} />
      <Route path="/adicionais" element={<AdditionalManager />} />
      <Route path="/cupons" element={<CouponManager onEditTexts={(coupon) => navigate(`/edit-coupon-texts/${coupon.id}`, { state: { coupon } })} onEditImages={(coupon) => navigate(`/edit-coupon-images/${coupon.id}`, { state: { coupon } })} />} />
      <Route path="/edit-coupon-texts/:id" element={<EditCouponTexts />} />
      <Route path="/edit-coupon-images/:id" element={<EditCouponImages />} />
      <Route path="/clientes" element={<ClientManager />} />
      <Route path="/impostos" element={<TaxManager />} />
      <Route path="/gerador" element={<DescricaoAI />} />
    </Routes>
  );
};

const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
