import React from 'react';

const TextArea = ({ label, value, onChange, required = false }) => (
  <div>
    <label>{label}</label>
    <textarea value={value} onChange={onChange} required={required} />
  </div>
);

export default TextArea;
