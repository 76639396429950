import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h1>Bem-vindo à Loja Virtual</h1>
      <nav>
        <ul>
          <li>
            <Link to="/products">Listar Produtos</Link>
          </li>
          <li>
            <Link to="/new-product">Cadastrar Novo Produto</Link>
          </li>
          <li>
            <Link to="/categories">Categorias</Link>
          </li>
          <li>
            <Link to="/manage-products">Produtos</Link>
          </li>
          <li>
            <Link to="/adicionais">Adicionais</Link>
          </li>
          <li>
            <Link to="/cupons">Cupons Adicionar</Link>
          </li>
          <li>
            <Link to="/clientes">Clientes Cadastro</Link>
          </li>
          <li>
            <Link to="/impostos">Impostos</Link>
          </li>
          <li>
            <Link to="/gerador">Gerador de descrição AI</Link>
          </li>
          <li>
            <Link to="/chatbot">Chatbot Memória</Link>
          </li>
          <li>
            <Link to="/chat-historico">Histórico</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;
