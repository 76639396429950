import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useParams, useLocation } from 'react-router-dom';
import TextInput from './TextInput';
import TextArea from './TextArea';
import NumberInput from './NumberInput';
import DateInput from './DateInput';
import SelectInput from './SelectInput';
import FileInput from './FileInput';
import ImagePreview from './ImagePreview';
import TagInput from './TagInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const PRODUCT_FOLDER = 'loja-produtos/produtos/';
const CATEGORY_FOLDER = 'loja-produtos/categorias/';
const ADDITIONAL_FOLDER = 'loja-produtos/adicionais/';

const ProductForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const [nome, setNome] = useState('');
  const [breveDescricao, setBreveDescricao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [preco, setPreco] = useState('');
  const [precoPromocional, setPrecoPromocional] = useState('');
  const [dataValidadePromocao, setDataValidadePromocao] = useState('');
  const [categoria, setCategoria] = useState('');
  const [imagens, setImagens] = useState([]);
  const [video, setVideo] = useState('');
  const [imgBanner, setImgBanner] = useState(null);
  const [textoBanner, setTextoBanner] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [adicionais, setAdicionais] = useState([]);
  const [selectedAdicionais, setSelectedAdicionais] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [existingBanner, setExistingBanner] = useState('');

  useEffect(() => {
    if (location.state && location.state.product) {
      const product = location.state.product;
      setNome(product.nome);
      setBreveDescricao(product.breveDescricao);
      setDescricao(product.descricao);
      setPreco(product.preco);
      setPrecoPromocional(product.precoPromocional);
      setDataValidadePromocao(product.dataValidadePromocao ? new Date(product.dataValidadePromocao).toISOString().split('T')[0] : '');
      setCategoria(product.categoria);
      setImagens(product.imagens.map(img => ({ url: img }))); // Transform URLs to objects
      setVideo(product.video);
      setTextoBanner(product.textoBanner);
      setExistingBanner(product.imgBanner);
      setEditMode(true);
      setSelectedAdicionais(product.adicionais || []);
    }
  }, [location.state]);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCategoriesAndAdicionais = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: CATEGORY_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const categoryPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const categoryData = await Promise.all(categoryPromises);
        setCategorias(categoryData.filter(cat => cat !== null));

        const listAdditionalParams = {
          Bucket: S3_BUCKET,
          Prefix: ADDITIONAL_FOLDER,
        };
        const listedAdditionalObjects = await s3.listObjectsV2(listAdditionalParams).promise();
        const additionalPromises = listedAdditionalObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const additionalData = await Promise.all(additionalPromises);
        setAdicionais(additionalData.filter(add => add !== null));
      } catch (error) {
        console.error('Erro ao buscar categorias e adicionais:', error);
      }
    };

    fetchCategoriesAndAdicionais();
  }, []);

  const handleFileChange = (e, setState) => {
    const files = Array.from(e.target.files);
    if (files.length + imagens.length <= 5) {
      setState(prevImagens => [...prevImagens, ...files.map(file => ({ file }))]);
    } else {
      alert('Você pode enviar no máximo 5 imagens.');
    }
  };

  const handleRemoveImage = (index) => {
    setImagens(prevImagens => prevImagens.filter((_, i) => i !== index));
  };

  const handleBannerChange = (e) => {
    setImgBanner(e.target.files[0]);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      // Get the latest product number
      let latestNumber = 0;
      const listParams = {
        Bucket: S3_BUCKET,
        Prefix: PRODUCT_FOLDER,
      };
      const listedObjects = await s3.listObjectsV2(listParams).promise();

      if (listedObjects.Contents && listedObjects.Contents.length > 0) {
        const latestFile = listedObjects.Contents.map(item => item.Key)
          .filter(key => key.startsWith(PRODUCT_FOLDER))
          .sort()
          .pop();
        const match = latestFile.match(/(\d+)-/);
        if (match) {
          latestNumber = parseInt(match[1], 10);
        }
      }

      const nextNumber = editMode ? id : String(latestNumber + 1).padStart(3, '0');
      const sanitizedFileName = sanitizeFileName(nome);
      const sanitizedCategory = sanitizeFileName(categoria);
      const productFolder = `${PRODUCT_FOLDER}${sanitizedCategory}/${nextNumber}-${sanitizedFileName}/`;
      const imagesFolder = `${productFolder}images/`;
      const bannerFolder = `${productFolder}banner/`;
      const jsonFileName = `${productFolder}${sanitizedFileName}.json`;

      // Upload images to S3 with public read access
      const uploadedImageNames = [];
      for (let i = 0; i < imagens.length; i++) {
        const image = imagens[i];
        if (image.url) {
          uploadedImageNames.push(image.url);
        } else {
          const imageFileName = `${imagesFolder}${String(i + 1).padStart(2, '0')}.jpg`;
          const imageUploadParams = {
            Bucket: S3_BUCKET,
            Key: imageFileName,
            Body: image.file,
            ContentType: image.file.type,
            ACL: 'public-read'
          };
          await s3.upload(imageUploadParams).promise();
          uploadedImageNames.push(imageFileName);
        }
      }

      // Upload banner image to S3 with public read access
      let bannerImageName = '';
      if (imgBanner) {
        const bannerImageFileName = `${bannerFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: imgBanner,
          ContentType: imgBanner.type,
          ACL: 'public-read'
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = bannerImageFileName;
      } else if (editMode && existingBanner) {
        bannerImageName = existingBanner;
      }

      const productData = {
        id: nextNumber,
        nome,
        breveDescricao,
        descricao,
        preco: parseFloat(preco),
        precoPromocional: precoPromocional ? parseFloat(precoPromocional) : null,
        dataValidadePromocao: dataValidadePromocao ? new Date(dataValidadePromocao).toISOString() : null,
        categoria,
        imagens: uploadedImageNames.map(name => `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${name}`),
        video,
        imgBanner: bannerImageName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageName}` : '',
        textoBanner,
        adicionais: selectedAdicionais,
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(productData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Produto cadastrado com sucesso!');
    } catch (error) {
      console.error('Erro ao cadastrar produto:', error);
      alert('Erro ao cadastrar produto. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput label="Nome:" value={nome} onChange={(e) => setNome(e.target.value)} required />
      <TextArea label="Breve Descrição:" value={breveDescricao} onChange={(e) => setBreveDescricao(e.target.value)} required />
      <TextArea label="Descrição:" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
      <NumberInput label="Preço:" value={preco} onChange={(e) => setPreco(e.target.value)} required />
      <NumberInput label="Preço Promocional:" value={precoPromocional} onChange={(e) => setPrecoPromocional(e.target.value)} />
      <DateInput label="Data de Validade da Promoção:" value={dataValidadePromocao} onChange={(e) => setDataValidadePromocao(e.target.value)} />
      <SelectInput label="Categoria:" value={categoria} onChange={(e) => setCategoria(e.target.value)} options={categorias.map(cat => ({ value: cat.nome, label: cat.nome }))} required />
      <FileInput label="Imagens:" onChange={(e) => handleFileChange(e, setImagens)} multiple />
      <ImagePreview images={imagens} onRemove={handleRemoveImage} />
      <TextInput label="Vídeo (URL):" value={video} onChange={(e) => setVideo(e.target.value)} />
      <FileInput label="Imagem do Banner:" onChange={handleBannerChange} />
      {imgBanner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={URL.createObjectURL(imgBanner)}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      {editMode && existingBanner && !imgBanner && (
        <div style={{ marginTop: '10px' }}>
          <img
            src={existingBanner}
            alt="Preview Banner"
            style={{ width: '200px', height: '100px', objectFit: 'cover' }}
          />
        </div>
      )}
      <TextArea label="Texto do Banner:" value={textoBanner} onChange={(e) => setTextoBanner(e.target.value)} />
      <TagInput label="Adicionais:" options={adicionais} value={selectedAdicionais} onChange={setSelectedAdicionais} />
      <button type="submit">Cadastrar Produto</button>
    </form>
  );
};

export default ProductForm;
