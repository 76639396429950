import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import TextInput from './TextInput';
import TextArea from './TextArea';
import DateInput from './DateInput';
import FileInput from './FileInput';

const S3_BUCKET = 'ecommerce-app-strapi-expo';
const COUPON_FOLDER = 'loja-produtos/cupons/';

const CouponManager = ({ onEditTexts, onEditImages }) => {
  const [cupons, setCupons] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valorDesconto, setValorDesconto] = useState('');
  const [tipoDesconto, setTipoDesconto] = useState('dinheiro');
  const [dataValidade, setDataValidade] = useState('');
  const [banner, setBanner] = useState(null);
  const [fraseBanner, setFraseBanner] = useState('');

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchCupons = async () => {
      try {
        const listParams = {
          Bucket: S3_BUCKET,
          Prefix: COUPON_FOLDER,
        };
        const listedObjects = await s3.listObjectsV2(listParams).promise();
        const couponPromises = listedObjects.Contents.filter(item => item.Key.endsWith('.json')).map(async (item) => {
          const params = {
            Bucket: S3_BUCKET,
            Key: item.Key,
          };
          const data = await s3.getObject(params).promise();
          try {
            return JSON.parse(data.Body.toString('utf-8'));
          } catch (error) {
            console.error('Erro ao parsear JSON:', error, 'Arquivo:', item.Key);
            return null;
          }
        });

        const couponData = await Promise.all(couponPromises);
        setCupons(couponData.filter(cupom => cupom !== null));
      } catch (error) {
        console.error('Erro ao buscar cupons:', error);
      }
    };

    fetchCupons();
  }, []);

  const handleFileChange = (e) => {
    setBanner(e.target.files[0]);
  };

  const handleRemoveBanner = () => {
    setBanner(null);
  };

  const sanitizeFileName = (str) => {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const sanitizedFileName = sanitizeFileName(codigo);
      const id = String(cupons.length + 1).padStart(3, '0');
      const couponFolder = `${COUPON_FOLDER}${id}-${sanitizedFileName}/`;
      const jsonFileName = `${couponFolder}${sanitizedFileName}.json`;

      // Upload banner image to S3 with public read access
      let bannerImageName = '';
      if (banner) {
        const bannerFolder = `${couponFolder}banner/`;
        const bannerImageFileName = `${bannerFolder}banner.jpg`;
        const bannerImageUploadParams = {
          Bucket: S3_BUCKET,
          Key: bannerImageFileName,
          Body: banner,
          ContentType: banner.type,
          ACL: 'public-read'
        };
        await s3.upload(bannerImageUploadParams).promise();
        bannerImageName = bannerImageFileName;
      }

      const couponData = {
        id,
        codigo,
        descricao,
        valorDesconto: parseFloat(valorDesconto),
        tipoDesconto,
        dataValidade: new Date(dataValidade).toISOString(),
        fraseBanner,
        banner: bannerImageName ? `https://${S3_BUCKET}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${bannerImageName}` : '',
      };

      const uploadParams = {
        Bucket: S3_BUCKET,
        Key: jsonFileName,
        Body: JSON.stringify(couponData),
        ContentType: 'application/json',
        ACL: 'public-read'
      };

      await s3.upload(uploadParams).promise();
      alert('Cupom salvo com sucesso!');

      setCupons(prevCupons => [...prevCupons, couponData]);

      setCodigo('');
      setDescricao('');
      setValorDesconto('');
      setTipoDesconto('dinheiro');
      setDataValidade('');
      setBanner(null);
      setFraseBanner('');
      setShowAddForm(false);
    } catch (error) {
      console.error('Erro ao salvar cupom:', error);
      alert('Erro ao salvar cupom. Verifique as configurações e tente novamente.');
    }
  };

  const handleDelete = async (id) => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    try {
      const coupon = cupons.find(cupom => cupom.id === id);
      if (!coupon) {
        console.error('Cupom não encontrado:', id);
        alert('Cupom não encontrado.');
        return;
      }

      const sanitizedFileName = coupon.codigo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-z0-9]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');

      // List all objects in the coupon folder
      const couponFolder = `${COUPON_FOLDER}${id}-${sanitizedFileName}/`;
      const listParams = {
        Bucket: S3_BUCKET,
        Prefix: couponFolder,
      };
      const listedObjects = await s3.listObjectsV2(listParams).promise();

      // Delete all objects in the coupon folder
      const deleteParams = {
        Bucket: S3_BUCKET,
        Delete: {
          Objects: listedObjects.Contents.map(item => ({ Key: item.Key }))
        }
      };

      await s3.deleteObjects(deleteParams).promise();

      setCupons(cupons.filter(cupom => cupom.id !== id));
      alert('Cupom excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir cupom:', error);
      alert('Erro ao excluir cupom. Verifique as configurações e tente novamente.');
    }
  };

  return (
    <div>
      <h1>Gerenciamento de Cupons</h1>
      <button onClick={() => setShowAddForm(true)}>Adicionar Cupom</button>
      {showAddForm && (
        <form onSubmit={handleSubmit}>
          <TextInput label="Código:" value={codigo} onChange={(e) => setCodigo(e.target.value.toUpperCase())} required />
          <TextArea label="Descrição:" value={descricao} onChange={(e) => setDescricao(e.target.value)} required />
          <div>
            <label>Desconto:</label>
            <input type="text" value={valorDesconto} onChange={(e) => setValorDesconto(e.target.value)} required placeholder="0,00" />
            <select value={tipoDesconto} onChange={(e) => setTipoDesconto(e.target.value)}>
              <option value="dinheiro">Dinheiro</option>
              <option value="porcentagem">Porcentagem</option>
            </select>
          </div>
          <DateInput label="Data de Validade:" value={dataValidade} onChange={(e) => setDataValidade(e.target.value)} required />
          <FileInput label="Imagem do Banner:" onChange={handleFileChange} />
          {banner && (
            <div style={{ marginTop: '10px' }}>
              <img
                src={URL.createObjectURL(banner)}
                alt="Preview Banner"
                style={{ width: '200px', height: '100px', objectFit: 'cover' }}
              />
              <button type="button" onClick={handleRemoveBanner}>Remover</button>
            </div>
          )}
          <TextArea label="Frase para o Banner:" value={fraseBanner} onChange={(e) => setFraseBanner(e.target.value)} />
          <button type="submit">Salvar Cupom</button>
        </form>
      )}
      <ul>
        {cupons.map((coupon, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <span style={{ flexGrow: 1 }}>{coupon.codigo} - {coupon.tipoDesconto === 'dinheiro' ? `R$ ${coupon.valorDesconto.toFixed(2).replace('.', ',')}` : `${coupon.valorDesconto}%`} - {new Date(coupon.dataValidade).toLocaleDateString()}</span>
            <button onClick={() => onEditTexts(coupon)} style={{ marginRight: '10px' }}>Editar Textos</button>
            <button onClick={() => onEditImages(coupon)} style={{ marginRight: '10px' }}>Editar Imagem</button>
            <button onClick={() => handleDelete(coupon.id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CouponManager;
