import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProductList = ({ products, onDelete, onEditTexts, onEditImages }) => {
  const navigate = useNavigate();

  const handleViewProduct = (product) => {
    navigate(`/view-product/${product.id}`, { state: { product } });
  };

  return (
    <div>
      <h2>Produtos</h2>
      <ul>
        {products.map((product, index) => (
          <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img
              src={product.imagens[0]}
              alt={product.nome}
              style={{ width: '50px', height: '50px', marginRight: '10px', cursor: 'pointer' }}
              onClick={() => handleViewProduct(product)}
            />
            <span
              style={{ flexGrow: 1, cursor: 'pointer' }}
              onClick={() => handleViewProduct(product)}
            >
              {product.nome}
              {product.precoPromocional ? (
                <>
                  <span style={{ textDecoration: 'line-through', marginLeft: '10px' }}>R$ {product.preco.toFixed(2)}</span>
                  <span style={{ color: 'red', marginLeft: '10px' }}>R$ {product.precoPromocional.toFixed(2)}</span>
                </>
              ) : (
                <span style={{ marginLeft: '10px' }}>R$ {product.preco.toFixed(2)}</span>
              )}
            </span>
            <button onClick={() => onEditTexts(product)} style={{ marginRight: '10px' }}>Editar Textos</button>
            <button onClick={() => onEditImages(product)} style={{ marginRight: '10px' }}>Editar Imagens</button>
            <button onClick={() => onDelete(product.id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
