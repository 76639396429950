import AWS from 'aws-sdk';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const S3_BUCKET = 'ecommerce-app-strapi-expo';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    });

    const fetchProduct = async () => {
      try {
        const params = {
          Bucket: S3_BUCKET,
          Key: decodeURIComponent(id),
        };
        const data = await s3.getObject(params).promise();
        const productData = JSON.parse(data.Body.toString('utf-8'));

        console.log('Product data:', productData);

        if (productData.imagens && productData.imagens.length > 0) {
          setProduct(productData);
          setSelectedImage(productData.imagens[0]);
          console.log('Selected Image URL:', productData.imagens[0]);
        } else {
          setProduct(productData);
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do produto:', error);
        setProduct({ error: 'Erro ao carregar o produto. Tente novamente mais tarde.' });
      }
    };

    fetchProduct();
  }, [id]);

  if (!product) {
    return <div>Carregando...</div>;
  }

  if (product.error) {
    return <div>{product.error}</div>;
  }

  const decreaseQuantity = () => setQuantity(quantity > 1 ? quantity - 1 : 1);
  const increaseQuantity = () => setQuantity(quantity + 1);
  const totalPrice = (product.precoPromocional || product.preco) * quantity;

  return (
    <div>
      <h1>Detalhes do Produto</h1>
      <div>
        {selectedImage ? (
          <img src={selectedImage} alt={product.nome} />
        ) : (
          <div>Imagem não disponível</div>
        )}
        {product.precoPromocional && <div>Promoção</div>}
      </div>
      {product.imagens && product.imagens.length > 1 && (
        <div style={{ display: 'flex', overflowX: 'scroll', marginTop: '10px' }}>
          {product.imagens.map((imagem, index) => (
            <img
              key={index}
              src={imagem}
              alt={`${product.nome} ${index}`}
              onClick={() => setSelectedImage(imagem)}
              style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px', cursor: 'pointer' }}
            />
          ))}
        </div>
      )}
      <div>
        <h2>{product.nome}</h2>
        {product.precoPromocional ? (
          <div>
            <span style={{ textDecoration: 'line-through' }}>R$ {product.preco.toFixed(2)}</span>
            <span> R$ {product.precoPromocional.toFixed(2)}</span>
          </div>
        ) : (
          <div>
            <span>R$ {product.preco.toFixed(2)}</span>
          </div>
        )}
        <p>{product.descricao}</p>
        {product.precoPromocional && (
          <div>
            Promoção válida até {new Date(product.dataValidadePromocao).toLocaleDateString()}
          </div>
        )}
        <div>
          <button onClick={decreaseQuantity}>-</button>
          <span>{quantity}</span>
          <button onClick={increaseQuantity}>+</button>
        </div>
        <div>Total: R$ {totalPrice.toFixed(2)}</div>
        <button>Adicionar ao Carrinho</button>
      </div>
    </div>
  );
};

export default ProductDetails;
