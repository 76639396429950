import React from 'react';

const TagInput = ({ label, options, value, onChange }) => {
  const handleAddTag = (option) => {
    if (!value.find(tag => tag.id === option.id)) {
      onChange([...value, option]);
    }
  };

  const handleRemoveTag = (option) => {
    onChange(value.filter(tag => tag.id !== option.id));
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        {options.map((option, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleAddTag(option)}
            style={{ marginRight: '5px', marginBottom: '5px' }}
          >
            {option.nome} - R$ {option.preco.toFixed(2).replace('.', ',')}
          </button>
        ))}
      </div>
      <div>
        {value.map((tag, index) => (
          <span
            key={index}
            style={{
              display: 'inline-block',
              padding: '5px',
              margin: '5px',
              backgroundColor: '#ddd',
              borderRadius: '3px',
            }}
          >
            {tag.nome} - R$ {tag.preco.toFixed(2).replace('.', ',')}
            <button type="button" onClick={() => handleRemoveTag(tag)} style={{ marginLeft: '5px' }}>
              X
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagInput;
